<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        <b>
          {{
            inputs.language === 'en'
              ? 'Select the best answer'
              : 'Sélectionnez la meilleure réponse'
          }}
        </b>
        {{
          inputs.language === 'en'
            ? 'What type of bond best describes the bonds identified in the figure below?'
            : 'Quel type de liaison décrit le plus précisément les liaisons identifiées dans la structure suivante?'
        }}
      </p>

      <p class="pl-8 mb-4">
        <v-img style="max-width: 185px" :src="imageName" />
      </p>

      <v-select
        v-model="inputs.bondA"
        :items="items"
        item-text="text"
        item-value="value"
        label="Liaison A:"
        class="mb-0"
        style="width: 250px"
      >
        <template #item="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
        <template #selection="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
      </v-select>

      <v-select
        v-model="inputs.bondB"
        :items="items"
        item-text="text"
        item-value="value"
        label="Liaison B:"
        class="mb-n2"
        style="width: 250px"
      >
        <template #item="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
        <template #selection="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
      </v-select>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'Question490',
  components: {CalculationInput, ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        bondA: null,
        bondB: null,
      },
      items: [
        {text: 'Covalente', value: 'covalent'},
        {text: 'Covalente Polaire', value: 'polarCovalent'},
        {text: 'Ionique', value: 'ionic'},
      ],
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/bondIdentification1.png';
    },
  },
};
</script>
<style scoped></style>
